import React, {useEffect, useState} from "react"
import Layout from "../components/common/layout"
import { graphql } from "gatsby"
import murrineStyles from "../styles/pages/murrine.module.scss"
import MurrinaCard from "../components/cards/murrinaCard"

const ProntaConsegna = ({ data, location }) => {
  const murrine = data.allStrapiMurrine.edges || []

  console.log(location)

  return (
    <Layout
      pageMeta={{
        title: `Pronta Consegna`,
        keywords: [`lampade da tavolo`, `La Murrina`, `Antica Murrina Venezia`, `disponibili in negozio`, `spedizione immediata`],
        description: `Lampade da tavolo in vetro di Murano di La Murrina e Antica Murrina Venezia disponibili in pronta consegna`,
        link: `/pronta-consegna`,
      }}
      location={location}
    >
      <ul className={murrineStyles.murrineAltro}>
        {murrine.length ? (
          murrine.map(murrina => (
            (murrina.node.promo || !murrina.node.promo) && <MurrinaCard  key={murrina.node.strapiId} murrina={murrina} />
          ))
        ) : (
          <p className={murrineStyles.text}>
            non ci sono prodotti in questa categoria
          </p>
        )}
      </ul>
    </Layout>
  )
}
export default ProntaConsegna

export const query = graphql`
  query LampadeQuery {
    allStrapiMurrine(filter: {categoria: {eq: "lampade"}}) {
      edges {
        node {
          strapiId
          slug
          nome
          categoria
          prezzo
          promo {
            codice
            inizio
            fine
          }
          marchio {
            nome
            logo {
              alternativeText
              hash
              width
              height
              url
            }
          }
          variante_colore {
            colore
            quantita
            foto {
              alternativeText
              hash
              width
              height
              url
            }
          }
        }
      }
    }
  }
`